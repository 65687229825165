// extracted by mini-css-extract-plugin
export var node = "node-module--node--3M-XZ";
export var header = "node-module--header--2M_ZS";
export var zzz = "node-module--zzz--1xJ7Z";
export var meta = "node-module--meta--xJLFf";
export var submitted = "node-module--submitted--1fvJT";
export var byAuthor = "node-module--byAuthor--2cCeA";
export var content = "node-module--content--1gbJI";
export var viewModeFull = "node-module--viewModeFull--2jbx1";
export var onePlusFourGridSection = "node-module--onePlusFourGridSection--p5haK";
export var fieldPreperationTime = "node-module--fieldPreperationTime--328-B";
export var fieldCookingTime = "node-module--fieldCookingTime--1-HdM";
export var label = "node-module--label--w2GZ2";
export var item = "node-module--item--2FZnI";
export var fieldNumberOfServings = "node-module--fieldNumberOfServings--3telz";
export var fieldDifficulty = "node-module--fieldDifficulty---VHc2";
export var mediaImage = "node-module--mediaImage--3XCAS";